@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@800&family=Roboto:wght@400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, sans-serif;
    color: #212429;
  }

  body {
    overflow: auto !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  body::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
  }

}

@layer components {
  .btn-primary-fill {
    @apply bg-blue text-gray-900 hover:bg-blue-darker disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-secondary-fill {
    @apply bg-orange hover:bg-orange-darker text-white disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-disabled {
    @apply bg-gray-400  text-black py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-success {
    @apply bg-green-500 text-black py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-alternative-fill {
    @apply bg-gray-300 hover:bg-gray-400 text-gray-400 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-primary-outline {
    @apply border border-blue text-blue hover:border-blue-darker hover:text-blue-darker disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-red-outline {
    @apply border border-red-700 text-red-700  hover:border-red-900 hover:text-red-900 disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-secondary-outline {
    @apply border border-orange text-orange hover:border-orange-darker hover:text-orange-darker disabled:opacity-50 py-2 px-3 min-w-[80px] rounded-xl font-medium;
  }

  .btn-alternative-outline {
    @apply text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-xl py-2 px-3 min-w-[80px];
  }

  .btn-dropdown-menu {
    @apply w-full relative justify-between items-center flex py-2 px-3 text-gray-700 bg-white border border-gray-300 rounded-lg text-sm shadow-sm placeholder-slate-300;
  }

  .badge-sucess {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-green-300 text-black rounded-full;
  }

  .badge-warning {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-yellow-300 text-black rounded-full;
  }

  .badge-danger {
    @apply inline-flex items-center justify-center px-2 py-1 font-medium leading-none bg-red-300 text-black rounded-full;
  }

  .select-many {
    @apply w-full block text-black bg-white border border-gray-300 rounded-lg text-sm shadow-sm placeholder-slate-300
    focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
    invalid:border-red-500   focus:invalid:border-red-500 focus:invalid:ring-red-500;
  }

  .bg-orange{
    background-color: #f7a600;
  }

  .card-special{
    background: rgb(236, 236, 236);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .bg-orange-darker{
    background-color: #df9707;
  }

  .bg-slitly-orange{
    background-color: #fff4ed;
  }
  
  .text-orange{
    color: #f7a600;
  }

  .text-orange-darker{
    color: #df9707;
  }

  .border-orange{
    border-color: #f7a600;
  }

  .border-orange-darker{
    border-color: #df9707;
  }

  .bg-blue{
    background-color: #00b6da;
  }

  .bg-blue-darker{
    background-color: #17ACCA;
  }

  .text-blue{
    color: #00b6da;
  }

  .text-blue-darker{
    color: #17ACCA;
  }

  .border-blue{
    border-color: #00b6da;
  }

  .border-blue-darker{
    border-color: #17ACCA;
  }

  .completely-centeredProfile {
    position: fixed;
    top: 50%;
    left: 55%;

    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .completely-centered {
    position: fixed;
    top: 45%;
    left: 45%;

    /* bring your own prefixes */
    transform: translate(-50%, -50%);
  }

  .mr-fix-important {
    margin-right: -2vh !important; /* -28px  */
  }

  select:disabled.hide-arrow-select::-ms-expand {
    -moz-appearance: none;
    -webkit-appearance: none;
    display: none;
  }

  #GridToolbarQuickFilter::placeholder {
    color: #718096;
    opacity: 1;
  }

  /*  transition css  */

  .transition-height {
    transition-property: height;
    transition-duration: 0.5s;
  }

  .slide-enter {
    opacity: 0;
    transform: translateY(-20px);
    }

  .slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }

  .slide-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .slide-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 300ms, transform 300ms;
  }


}

.w-halfscreen {
  width: 50vw !important;

  /* 100vh */
}

@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply w-full block text-black bg-white border border-gray-300 rounded-lg text-sm shadow-sm placeholder-slate-300
    focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
    invalid:border-red-500   focus:invalid:border-red-500 focus:invalid:ring-red-500;
  }

  input:focus, textarea:focus, select:focus{
    --tw-ring-opacity: none !important;
    outline: none !important;
  }

  [type="checkbox"] {
    @apply rounded border-gray-300 text-orange;
  }

  [type="radio"] {
    @apply rounded-full border-gray-300 text-orange;
  }
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* For disabling react-router <NavLink> cf. https://stackoverflow.com/a/38321726 */
.disabled-link {
  cursor: not-allowed;
}
